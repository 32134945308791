@import url(//db.onlinewebfonts.com/c/661fe2133bd923041dde83ab2cfe015c?family=Corona);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Cloister Black";
  src: url("http://db.onlinewebfonts.com/t/6ca68131925d7aa08c97e57d9e8b2ed7.eot");
  src: url("http://db.onlinewebfonts.com/t/6ca68131925d7aa08c97e57d9e8b2ed7.eot?#iefix")
      format("embedded-opentype"),
    url("http://db.onlinewebfonts.com/t/6ca68131925d7aa08c97e57d9e8b2ed7.woff2")
      format("woff2"),
    url("http://db.onlinewebfonts.com/t/6ca68131925d7aa08c97e57d9e8b2ed7.woff")
      format("woff"),
    url("http://db.onlinewebfonts.com/t/6ca68131925d7aa08c97e57d9e8b2ed7.ttf")
      format("truetype"),
    url("http://db.onlinewebfonts.com/t/6ca68131925d7aa08c97e57d9e8b2ed7.svg#Cloister Black")
      format("svg");
}
@font-face {
  font-family: "Old English Text MT";
  src: url("//db.onlinewebfonts.com/t/527ea5851ca62a9a758a44dc39437eae.eot");
  src: url("//db.onlinewebfonts.com/t/527ea5851ca62a9a758a44dc39437eae.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/527ea5851ca62a9a758a44dc39437eae.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/527ea5851ca62a9a758a44dc39437eae.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/527ea5851ca62a9a758a44dc39437eae.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/527ea5851ca62a9a758a44dc39437eae.svg#Old English Text MT")
      format("svg");
}

.test span {
  font-family: "Corona";
  font-size: 1.5em;
}

.test1 .MuiListItemText-primary,
.test1 .MuiListItemText-secondary {
  font-family: "Corona";
}
#root {
  height: 100vh;
}
.App {
  text-align: center;
  background-color: rgba(239, 198, 141, 1) !important;
  max-width: 1000px;
  margin: 0 auto;
}
html {
  background-color: rgba(239, 198, 141, 1) !important;
}

body {
  background-color: rgba(239, 198, 141, 1) !important;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing {
  font-weight: 400;
  font-size: 1em;
  font-family: "Corona";
}
p {
  max-width: 90vw !important;
}
img {
  width: 95vw;
  border-radius: 0.5em;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.logo {
  padding-top: 3em;
  padding-bottom: 2em;
  width: 8em;
  box-shadow: none;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.MuiListSubheader-sticky {
  position: relative !important;
}

hr {
  border: 1px solid black;
  width: 90%;
  -webkit-margin-before: 0.2em !important;
          margin-block-start: 0.2em !important;
  -webkit-margin-after: 0.2em !important;
          margin-block-end: 0.2em !important;
}

.MuiAppBar-colorDefault,
.MuiTypography-root {
  background-color: rgba(239, 198, 141, 1) !important;
}

.Mui-selected {
  color: black !important;
}

.PrivateTabIndicator-colorPrimary-61,
.PrivateTabIndicator-colorPrimary-86 {
  background-color: black !important;
}

a {
  text-decoration: none;
  color: black;
  font-family: Corona;
  font-size: 20px;
  width: 85%;
  font-weight: bold;
  text-align: start;
  margin-left: 16px;
}
.arrows {
  -webkit-animation-name: move;
          animation-name: move;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin-bottom: 10px;
}

@-webkit-keyframes move {
  50% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
}

@keyframes move {
  50% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
}
#nested-list-subheader h3 {
  -webkit-margin-before: 0px !important;
          margin-block-start: 0px !important;
  -webkit-margin-after: 0px !important;
          margin-block-end: 0px !important;
  padding: none;
  font-family: "Corona";
}

#name {
  font-family: "Old English Text MT";
  font-size: 2.3em;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

span.MuiTab-wrapper {
  font-family: "Corona";
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

#edition {
  font-size: 20px;
  font-family: "Corona";
  font-weight: bold;
  padding-bottom: 10px;
}

#header-menu {
  font-family: "Corona";
  font-weight: bold;
  font-size: 1.5em;
}

#bottom {
  -webkit-margin-before: 10px !important;
          margin-block-start: 10px !important;
}
#top {
  -webkit-margin-after: 10px !important;
          margin-block-end: 10px !important;
}

#bottom2 {
  -webkit-margin-after: 16px !important;
          margin-block-end: 16px !important;
}
#bottom3 {
  -webkit-margin-after: 20px !important;
          margin-block-end: 20px !important;
}
#welcome {
  font-size: 28px;
  font-family: "Corona";
  font-weight: bold;
}

#menu-divider {
  width: 90%;

  -webkit-margin-before: -0.3em !important;

          margin-block-start: -0.3em !important;
  -webkit-margin-after: 1em !important;
          margin-block-end: 1em !important;
  border-color: black;
}

#aaa {
  padding-bottom: 12px;
}

.fishes {
  font-family: "Corona";
}

.arrow {
  display: flex;
  justify-content: center;
}

.item {
  margin: 0;
  font-family: "Corona";
  font-size: 1.25rem;
  margin-right: 0.5em;
  word-wrap: break-word;
}
.description {
  font-size: 1.1rem;
  font-family: "Corona";
  color: rgba(0, 0, 0, 0.85);
  text-align: left;
  padding: 16px;
  margin: 0;
}
.breakkie {
  margin: 8px !important;
  font-size: 14px;
}
#menubox {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  width: 100%;
}
#itembox {
  display: flex;
  justify-content: space-between;
}

#nested-list-subheader h4 {
  font-family: "Corona";
  -webkit-margin-before: 0 !important;
          margin-block-start: 0 !important;
  -webkit-margin-after: 0 !important;
          margin-block-end: 0 !important;
  line-height: 24px;
}
#nested-list-subheader h3 {
  font-family: "Corona";
  -webkit-margin-before: 0 !important;
          margin-block-start: 0 !important;
  -webkit-margin-after: 0 !important;
          margin-block-end: 0 !important;
  line-height: 24px;
}

.MuiList-subheader {
  background-color: rgba(239, 198, 141, 1) !important;
}

.MuiTabs-indicator {
  background-color: black !important;
}

#back-home {
  background-color: rgba(239, 198, 141, 1) !important;
  margin: 0;
  font-family: "Corona";
}

.MuiListSubheader-root {
  color: black !important;
}

h4 {
  font-family: "Corona";
  font-weight: normal !important;
}

@media only screen and (max-width: 500px) {
  #name {
    font-size: 2.2em;
  }
  #welcome {
    font-size: 24px;
  }
  #edition {
    font-size: 18px;
  }
}

@media only screen and (max-width: 380px) {
  #name {
    font-size: 1.8em;
  }
  #welcome {
    font-size: 20px;
  }
  #edition {
    font-size: 14px;
  }
  a {
    font-size: 20px;
  }
}

header {
  -webkit-padding-after: 1em;
          padding-block-end: 1em;
}

span,
p {
  font-family: "Corona";
}

.desc {
  color: rgba(0, 0, 0, 0.54);
}

.drink-divide {
  width: 100vw;
}

.MuiListItem-gutters {
  padding-top: 0px;
}

